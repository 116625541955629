import * as React from 'react';
import { connectCurrentRefinements } from 'react-instantsearch-dom';

const ClearRefinements = ({ items, refine }) => {
  const cssClasses = ['faceted-search-clear-filters'];
  if (items.length === 0) {
    cssClasses.push('faceted-search-clear-filters--empty');
  }

  return (
    <div className={cssClasses.join(' ')}>
      <button onClick={() => refine(items)} disabled={!items.length}>
        {I18n.t('faceted_search.filters.clear_all')}
      </button>
    </div>
  );
};

export const FacetedSearchClearFilters = () => {
  const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);
  return <CustomClearRefinements/>
};
