import React from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { wpSourceDefaults } from './commands';
import * as Yup from 'yup';
import { array, object, string, number } from "yup";

Yup.addMethod(Yup.string, 'commaSeparatedValues', function (errorMessage) {
  return this.test(`test-card-length`, errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) {
      return true;
    }

    let processed = value
      .split(',')
      .map(item => item.trim())
      .filter(isFinite)
      .join(',');

    return (
      (value === processed) ||
      createError({ path, message: errorMessage })
    );
  });
});

const sourcesSchema = Yup.object().shape({
  wp: array()
    .of(
      object().shape({
        host: string().required("Can't be blank").url('Invalid URL'),
        categoryIds: string().commaSeparatedValues('Invalid format'),
        tagIds: string().commaSeparatedValues('Invalid format'),
        count: number().required("Can't be blank").positive('Must be a positive integer').integer(),
      })
    )
    .min(1)
});

/**
 * Feed aggregator sources form.
 *
 * @param {Function} submitHandler
 * @param {Object} defaultValues
 * @returns {Element}
 * @constructor
 */
const FeedAggregatorSourcesForm = ({submitHandler, defaultValues}) => {
  return <>
    <Formik initialValues={defaultValues} validationSchema={sourcesSchema} onSubmit={values => submitHandler(values)}>
      {({values}) => (
        <Form>
          <div className='form-fields'>
          <h2 className='h--smallest text-light'>WordPress API</h2>
          <FieldArray name='wp'>
            {({ remove, push }) => (
              <div className='col-12 mb-4'>
                {values.wp.length > 0 &&
                  values.wp.map((entry, idx) => (
                    <div className='row mb-4' key={idx}>
                      <div className='col-12 mb-3'>
                        <label className='form-label' htmlFor={`wp[${idx}].host`}>Host</label>
                        <Field className='form-control form-control-sm' name={`wp[${idx}].host`} placeholder='https://developer.nvidia.com/blog' type='text' required/>
                        <ErrorMessage name={`wp[${idx}].host`} component='p' className='field-error mt-2 mb-0'/>
                        <p className='p--small text-light pt-1'>Base URL without a trailing slash (e.g., https://developer.nvidia.com/blog)</p>
                      </div>
                      <div className='col-4 mb-0'>
                        <label className='form-label' htmlFor={`wp[${idx}].count`}>Count</label>
                        <Field className='form-control form-control-sm' name={`wp[${idx}].count`} placeholder='3' type='number'/>
                        <ErrorMessage name={`wp[${idx}].count`} component='p' className='field-error mt-2 mb-0'/>
                        <p className='p--small text-light pt-1'>Minimum # of items to show from this source</p>
                      </div>
                      <div className='col-4 mb-0'>
                        <label className='form-label' htmlFor={`wp[${idx}].categoryIds`}>Categories</label>
                        <Field className='form-control form-control-sm' name={`wp[${idx}].categoryIds`} placeholder='123,456' type='text'/>
                        <ErrorMessage name={`wp[${idx}].categoryIds`} component='p' className='field-error mt-2 mb-0'/>
                        <p className='p--small text-light pt-1'>Comma separated list of categories IDs</p>
                      </div>
                      <div className='col-4 mb-0'>
                        <label className='form-label' htmlFor={`wp[${idx}].tagIds`}>Tags</label>
                        <Field className='form-control form-control-sm' name={`wp[${idx}].tagIds`} placeholder='123,456' type='text'/>
                        <ErrorMessage name={`wp[${idx}].tagIds`} component='p' className='field-error mt-2 mb-0'/>
                        <p className='p--small text-light pt-1'>Comma separated list of tags IDs</p>
                      </div>
                      <Field name={`wp[${idx}].type`} type='hidden'/>
                      <div className='col-12 mb-3'>
                        <button type='button' className='btn btn-outline-warning btn-sm' onClick={() => remove(idx)}>Remove source</button>
                      </div>
                    </div>
                  ))}
                <button type='button' className='btn btn-outline-light btn-sm' onClick={() => push({...wpSourceDefaults})}>
                  Add source
                </button>
              </div>
            )}
          </FieldArray>

          </div>
          <button type='submit' className='btn btn-info btn-sm'>Update sources</button>
        </Form>
      )}
    </Formik>
  </>;
};

export default FeedAggregatorSourcesForm;
