import * as React from 'react';
import {connectCurrentRefinements} from 'react-instantsearch-dom';
import {cleanLabel} from './helper';

const FilterWithOneItem = ({item, filters, refine}) => {
  if (filters[item.id] && filters[item.id]['label']) {
    return <>
      <div className="ais-CurrentRefinements-category"><span
        className="ais-CurrentRefinements-categoryLabel">
        <span className="ais-CurrentRefinements-label">{cleanLabel(filters[item.id]['label'])}: </span>
        {cleanLabel(item.currentRefinement)}</span>
        <button type="button" className="ais-CurrentRefinements-delete" onClick={e => refine(item.value)}>✕</button>
      </div>
    </>
  }

  return <>{item.label}</>;
}

const FilterWithNestedItems = ({item, filters, refine}) => {
  return <>
    {item.items.map(nested => (
      <div className="ais-CurrentRefinements-category" key={nested.label}>
        <span className="ais-CurrentRefinements-label">{filters[item.id]['label']}: </span>
        <span className="ais-CurrentRefinements-categoryLabel">{nested.label}</span>
        <button type={'button'} className="ais-CurrentRefinements-delete" onClick={e => refine(nested.value)}>✕</button>
      </div>
    ))}
  </>
};

const CurrentRefinements = ({items, refine, filters, container}) => {
  const cssClasses = ['ais-CurrentRefinements'];
  if (items.length === 0) {
    cssClasses.push('ais-CurrentRefinements--empty');
  }
  if (container.current) {
    if (items.length === 0) {
      container.current.classList.add('faceted-search-pills--empty');
    } else {
      container.current.classList.remove('faceted-search-pills--empty');
    }
  }

  return <div className={cssClasses.join(' ')}>
    <ul className="ais-CurrentRefinements-list">
      {items.map(item => (
        <li key={item.label} className="ais-CurrentRefinements-item">
          {item.items ? <FilterWithNestedItems item={item} filters={filters} refine={refine}/> : <FilterWithOneItem item={item} filters={filters} refine={refine}/>}
        </li>
      ))}
    </ul>
  </div>
};

export const FacetedSearchPills = ({filters, container}) => {
  const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);

  return <CustomCurrentRefinements {...{filters, container}} />
};
