/**
 * Sanitizes GrapesJS editor option.
 *
 * @param {String} string
 * @returns {String}
 */
export const sanitizeEditorOption = (string) => {
  return string.replace(/['"\\]+/g, '');
}

/**
 * Builds component label with icon.
 *
 * @param {String} label
 * @param {String} icon
 * @returns {String}
 */
export const componentLabel = (label, icon) => {
  return `<div class='gjs-fonts gjs-f-b1'>
  <div class='gjs-fa-icon'><img src="${icon}" alt=${label}/></div>
  ${label}
</div>`;
};

/**
 * Converts react component with children to HTML.
 *
 * @param {Object} thisRef
 * @returns {String}
 */
export const componentWithChildrenToHTML = (thisRef) => {
  let klass = thisRef.get('type');
  const propsObject = thisRef.getAttrToHTML();

  let innerComponents = thisRef.components();
  let childHtml = '';
  if (innerComponents && innerComponents.length) {
    innerComponents.forEach((innerComponent) => {
      childHtml += innerComponent.toHTML();
    });
    let childSelectorClass = `${klass}-${thisRef.ccid}-child`;
    childHtml = `<div class="${childSelectorClass} visually-hidden">${childHtml}</div>`;
    propsObject['childSelector'] = `.${childSelectorClass}`;
  }

  let props = JSON.stringify(propsObject);
  props = thisRef.escapeHTML(props);

  return `${childHtml}<div data-react-class="${klass}" data-react-props="${props}" data-react-cache-id="${klass}-${thisRef.ccid}"></div>`;
};

export function imagePlaceholderUrl() {
  return window.imagePlaceholderUrl;
}
