import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Hits, HitsPerPage, InstantSearch, Pagination, ScrollTo} from 'react-instantsearch-dom';
import { Configure } from 'react-instantsearch-dom';
import {
  FieldsMappingContext,
  FieldsMappingContextProvider,
  ViewOptionsContext,
} from './Context';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import {mappingTypes} from '../data-mapping';
import {FacetedSearchSidebar} from './FacetedSearchSidebar';
import {FacetedSearchPills} from './FacetedSearchPills';
import {FacetedSearchStats} from './FacetedSearchStats';
import {FacetedSearchClearFilters} from './FacetedSearchClearFilters';
import {CardSearchResult} from './search-results/CardSearchResult';
import {AccordionSearchResult} from './search-results/AccordionSearchResult';
import {useLocation, useHistory} from 'react-router-dom';
import qs from 'qs';

const createURL = state => `?${qs.stringify(state)}`;
const searchStateToUrl = searchState => searchState ? createURL(searchState) : '';
const urlToSearchState = location => qs.parse(location.search.slice(1));

/**
 * Builds search client adapter.
 *
 * @param {String} protocol
 * @param {String} host
 * @param {String} port
 * @param {String} apiKey
 * @param {Object} collection
 * @param {String} collection.name
 * @param {String} queryBy
 * @param {String} filterBy
 * @returns {TypesenseInstantSearchAdapter}
 */
function buildSearchClientAdapter(protocol, host, port, apiKey, collection, queryBy, filterBy) {
  const {name} = collection;
  // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
  const cacheTime = 2 * 60; // TODO Move to collection config?
  const config = {
    server: {
      apiKey: apiKey,
      nodes: [
        {
          host: host,
          port: port,
          path: '',
          protocol: protocol,
        },
      ],
      cacheSearchResultsForSeconds: cacheTime,
    },
    collectionSpecificSearchParameters: {},
  };

  config.collectionSpecificSearchParameters[name] = {
    query_by: 'title'
  };

  if (filterBy) {
    config.additionalSearchParameters = {
      filter_by: filterBy,
    }
  }

  if (queryBy && queryBy.trim().length > 0) {
    config.collectionSpecificSearchParameters[name]['query_by'] = queryBy;
  }

  return new TypesenseInstantSearchAdapter(config);
}

/**
 * Search results factory.
 *
 * @param {Object} props
 * @param {string} props.viewMode
 * @param {Object} props.fieldsMapping
 * @param {Object} props.fieldsMapping.card
 * @param {Object} props.fieldsMapping.accordion
 * @returns {JSX.Element}
 * @constructor
 */
const SearchResults = (props) => {
  const {viewMode, fieldsMapping} = props;
  const {card, accordion} = useContext(FieldsMappingContext);
  const [, setCardData] = card;
  const [, setAccordionData] = accordion;

  useEffect(() => {
    setCardData(fieldsMapping.card);
    setAccordionData(fieldsMapping.accordion);
  }, []);

  switch (viewMode) {
    case mappingTypes.card:
      return <Hits hitComponent={CardSearchResult}/>;
    case mappingTypes.accordion:
      return <Hits hitComponent={AccordionSearchResult}/>;
    default:
      return <>View mode is not supported.</>;
  }
};

/**
 * Faceted search main component.
 *
 * @param {Object} props
 * @param {Object} props.collection
 * @param {string} props.collection.name
 * @param {Array}  props.collection.filters
 * @param {Object} props.collection.field_mappings
 * @param {Object} props.collection.field_mappings.card
 * @param {Object} props.collection.field_mappings.accordion
 * @param {string} props.viewMode
 * @param {string} props.title
 * @param {string} props.host
 * @param {string} props.protocol
 * @param {string} props.apiKey
 * @returns {JSX.Element}
 * @constructor
 */
export const FacetedSearchLayout = (props) => {
  const {collection, viewMode, title, itemsPerPage, viewOptions, filterBy = '', disabledFilters} = props;
  const {customSortBy, customSortByLabel} = props;
  const {protocol,  host, port, apiKey, queryBy} = props;
  const {filters, sort_by: sortBy, field_mappings: fieldsMapping} = collection;
  const {card: cardViewOptionsCtx} = useContext(ViewOptionsContext);
  const adapter = buildSearchClientAdapter(protocol, host, port, apiKey, collection, queryBy, filterBy);
  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const isStateChanged = (a, b) => JSON.stringify(a) !== JSON.stringify(b);
  const quickFiltersRef = useRef();

  useEffect(() => {
    const nextSearchState = urlToSearchState(location);
    if (isStateChanged(searchState, nextSearchState)) {
      setSearchState({...nextSearchState});
    }
  }, [location]);

  function onSearchStateChange(nextSearchState) {
    if (isStateChanged(searchState, nextSearchState)) {
      history.push(searchStateToUrl(nextSearchState), nextSearchState);
      setSearchState(nextSearchState);
    }
  }

  // Styling.
  const cssClasses = ['faceted-filters-results'];
  const cssClassesHeading = ['faceted-search-heading'];
  const cssClassesStats = ['faceted-search-stats-wrapper'];
  const cssClassesFacetedSearchPills = ['faceted-search-pills'];
  switch (viewMode) {
    case mappingTypes.card:
      cssClasses.push('faceted-filters-results--cards');
      cssClassesHeading.push('fsf-heading--cards');
      cssClassesFacetedSearchPills.push('faceted-search-pills--cards');
      cssClassesStats.push('faceted-search-stats-wrapper--cards');
      break;
    case mappingTypes.accordion:
      cssClasses.push('accordion');
      cssClassesHeading.push('fsf-heading--accordion');
      cssClassesFacetedSearchPills.push('faceted-search-pills--accordion');
      break;
  }

  useEffect(() => {
    const [, setCardViewOptions] = cardViewOptionsCtx;
    setCardViewOptions(viewOptions);
  }, [viewOptions]);

  return (
    <InstantSearch
      indexName={collection.name}
      searchClient={adapter.searchClient}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      {filterBy && filterBy.length && <Configure filters={filterBy}/>}
      <div className="container faceted-filters">
        <div className="row">
          {title && <div className="col-lg-12 col-md-12 col-sm-12">
            <h2 className={cssClassesHeading.join(' ')}>{title}</h2>
          </div>}
          <div className="col-lg-3 col-md-12 col-sm-12">
            <FacetedSearchSidebar sortBy={sortBy}
                                  customSortBy={customSortBy}
                                  customSortByLabel={customSortByLabel}
                                  filters={filters}
                                  disabledFilters={disabledFilters}
                                  collectionName={collection.name} />
          </div>
          <div className="col-lg-9 col-md-12 col-sm-12">
            <div className={cssClassesStats.join(' ')}>
              <FacetedSearchStats/>
              <HitsPerPage defaultRefinement={itemsPerPage} items={[{ value: itemsPerPage, label: `${itemsPerPage} items per page` }]}/>
            </div>

            <div className={cssClassesFacetedSearchPills.join(' ')} ref={quickFiltersRef}>
              <FacetedSearchClearFilters/>
              <div className="ais-CurrentRefinements-outer">
                <FacetedSearchPills filters={filters} container={quickFiltersRef}/>
              </div>
            </div>

            <div className={cssClasses.join(' ')}>
              <FieldsMappingContextProvider>
                <ScrollTo>
                  <SearchResults viewMode={viewMode} fieldsMapping={fieldsMapping}/>
                </ScrollTo>
              </FieldsMappingContextProvider>
            </div>

            <div className="faceted-filters__pagination">
              <Pagination showFirst={true} showLast={true} translations={{previous: "Previous", next: "Next"}}/>
            </div>
          </div>
        </div>
      </div>
    </InstantSearch>
  );
}
