import {AddOptionsSetTrait} from './traits/options-set';
import {AddAutocompleteTrait} from './traits/autocomplete';
import AddDescriptionTrait from './traits/description';
import AddRunCommandTrait from './traits/run-command';
import initWebformComponent from './webform/editor';
import initDiscourseTopicsComponent from './discourse-topics';
import {initCustomHtmlComponent} from './custom-html/editor';
import {initFacetedSearchComponent} from './faceted-search/editor';
import initPageNavigation from './page-navigation/editor';
import initAccordionComponent from './accordion/editor';
import initFeedAggregatorComponent from './feed-aggregator/editor';
import initGalleryWidget from './gallery-widget/editor';
import initImageComparisonSlider from './image-comparison-slider/editor';
import initCommands from './commands';
import initLegacyChart from './chart';
import initContextualHeroBanner from './contextual-hero-banner';
import initAddEventCalendar from './add-event-calendar';

export default (editor, opts = {}) => {
  editor.on('load', () => document.dispatchEvent(new CustomEvent('nv.page-builder.loaded', {detail: {editor, opts}})));

  initCommands(editor);

  // Traits
  AddAutocompleteTrait(editor);
  AddOptionsSetTrait(editor);
  AddDescriptionTrait(editor);
  AddRunCommandTrait(editor);

  // Components
  initWebformComponent(editor, opts);
  initCustomHtmlComponent(editor, opts);
  initFacetedSearchComponent(editor, opts);
  initDiscourseTopicsComponent(editor, opts)
  initPageNavigation(editor, opts);
  initAccordionComponent(editor, opts);
  initFeedAggregatorComponent(editor, opts);
  initGalleryWidget(editor, opts);
  initLegacyChart(editor, opts);
  initImageComparisonSlider(editor, opts);
  initContextualHeroBanner(editor, opts);
  initAddEventCalendar(editor, opts);
};
