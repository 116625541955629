import * as React from 'react';
import {useRef} from 'react';
import {RefinementList} from 'react-instantsearch-dom';
import {ChevronSmall, toggleFilterState} from '../helper';

// https://www.algolia.com/doc/api-reference/widgets/refinement-list/react/#widget-param-limit
const REFINEMENT_LIST_LIMIT = 20;

/**
 * Panel with checkboxes.
 *
 * @param {Object} props
 * @param {string} props.attributeName
 * @param {string} props.header
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const FacetedSearchRefinementList = (props) => {
  const panelElement = useRef();

  const {attributeName, header} = props;
  return <div className="fs-widget fs-widget--checkboxes">
    <div className="fs-widget__panel" ref={panelElement}>
      <div className="ais-Panel">
        <div className="ais-Panel-header" onClick={() => toggleFilterState(panelElement.current)}>
          <div className="ais-Panel-header-line"></div>
          {header}
          <div className="fs-widget__toggler">
            <ChevronSmall/>
          </div>
        </div>
        <div className="ais-Panel-body">
          <RefinementList
            attribute={attributeName}
            searchable={false}
            limit={REFINEMENT_LIST_LIMIT}
            transformItems={(items) => items.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)}
            translations={{placeholder: ''}}/>
        </div>
      </div>
    </div>
  </div>
}
