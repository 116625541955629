import React from 'react';
import ReactDOM from 'react-dom';
import FeedAggregatorSourcesForm from './FeedAggregatorSourcesForm';

export const wpSourceDefaults = {
  host: '',
  count: 3,
  categoryIds: '',
  tagIds: '',
  type: 'wp-api',
}

export const initialValues = {
  wp: []
};

export default function loadCommands(editor, options) {

  const {Commands} = editor;
  const {modalTitle, componentKey, componentCommandName} = options;
  const commandInstance = {
    componentKey,
    run(editor, sender, opts = {}) {
      this.editor = editor;
      this.options = opts;
      this.target = opts.target || editor.getSelected();
      const {target} = this;
      const componentData = target.get(componentKey) || initialValues;
      let content = '<div id="feed-aggregator__sources"></div>';
      editor.Modal
        .open({title: modalTitle, content})
        .getModel()
        .once('change:open', () => editor.stopCommand(this.id));

      const reactRootElement = document.getElementById('feed-aggregator__sources')
      ReactDOM.render(<FeedAggregatorSourcesForm defaultValues={componentData} submitHandler={(formValues) => {
        const {editor, target} = this;

        target.addAttributes({ 'sources': formValues });
        target.set(componentKey, formValues);
        editor.Modal.close();
      }}/>, reactRootElement);
    },
    stop() {
      editor.Modal.close();
    }
  };

  Commands.add(componentCommandName, commandInstance);
}
