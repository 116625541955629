import * as React from 'react';
import {useEffect, useState} from 'react';
import classnames from 'classnames';
import axios from 'axios';
import {loadDataFromSource, normalizeResponseData} from './feed-aggregator/api';
import {FeedAggregatorDataProvider, FeedAggregatorLayout} from './feed-aggregator/editor';
import {FourColumnsLayout, ThreeColumnsLayout} from './feed-aggregator/layouts';

const DEFAULT_COUNT = 4;

export const StateContext = React.createContext({
  itemsCount: DEFAULT_COUNT,
  showDate: false,
  showExcerpt: true,
  ctaText: 'Read more',
  isDataLoaded: false,
  error: false,
  cards: [],
});

const handleFailedResponse = (error) => {
  if (axios.isAxiosError(error)) {
    console.log('Network error');
  }
};

/**
 * Loads data from remote sources.
 *
 * @param {Array} sources
 * @param {Number} count
 * @returns {Promise<*[]>}
 */
async function getDataFromSources(sources, count) {
  let allPosts = [];
  let curatedPosts = [];

  // count should never be less than the sum of minCounts
  let minCount = sources.map(x => x.count).reduce((a, b) => a + b, 0);
  if (count < minCount) {
    count = minCount;
  }

  while (sources.length > 0) {
    let source = sources.shift();
    const {dataProvider: provider} = source;
    const responseData = await loadDataFromSource(source, count);
    const normalized = await normalizeResponseData(responseData, provider);

    normalized.forEach(post => allPosts.push({...post}));

    // filter for min # of requested posts per source
    normalized.slice(0, source.count).forEach(post => curatedPosts.push({...post}));
  }

  let postsMinusCurated = allPosts.filter(og => !curatedPosts.some(cu => cu.id === og.id));
  let sortedPostsMinusCurated = postsMinusCurated.sort((x, y) => {
    return y.timestamp - x.timestamp
  });

  let remainingCount = count - curatedPosts.length;

  if (remainingCount < 0) {
    remainingCount = 0;
  }

  let fillerPosts = sortedPostsMinusCurated.slice(0, remainingCount);
  curatedPosts = curatedPosts.concat(fillerPosts);

  // sort by newest
  let sortedPosts = curatedPosts.sort((x, y) => {
    return y.timestamp - x.timestamp;
  });

  return sortedPosts;
}

/**
 * Feed Aggregator component.
 *
 * @param {Object} props
 * @param {Number|String} props.items_to_display
 * @param {Boolean} props.show_date
 * @returns {Element}
 * @constructor
 */
const FeedAggregator = (props) => {
  const {sources, items_to_display} = props;
  const {layout, show_date, show_excerpt, cta_text} = props;
  const [componentState, setComponentState] = useState({
    itemsCount: items_to_display ? parseInt(items_to_display): DEFAULT_COUNT,
    showDate: show_date,
    showExcerpt: show_excerpt,
    ctaText: cta_text,
    isDataLoaded: false,
    error: false,
    cards: [],
  });

  useEffect(() => {
    const processedSources = [];
    Object.values(sources).forEach((sourcesGroup, idx) => {
      if (Array.isArray(sourcesGroup)) {
        sourcesGroup.forEach((source) => {
          const {type} = source;
          switch (type) {
            case FeedAggregatorDataProvider.wordpressApi:
              let {categoryIds, tagIds} = source;
              if (categoryIds) {
                categoryIds = categoryIds.split(',').map(id => id.trim()).filter(id => id.length);
              }
              if (tagIds) {
                tagIds = tagIds.split(',').map(id => id.trim()).filter(id => id.length);
              }
              processedSources.push({...source, categoryIds, tagIds, dataProvider: FeedAggregatorDataProvider.wordpressApi});
              break;
          }
        });
      }
    });

    let count = items_to_display || DEFAULT_COUNT;

    setComponentState({...componentState, itemsCount: count});

    getDataFromSources(processedSources, count)
      .then(data => setComponentState({...componentState, cards: data, isDataLoaded: true, error: false}))
      .catch(error => {
        setComponentState({...componentState, error: true});
        handleFailedResponse(error)
      });
  }, [items_to_display, sources]);

  useEffect(() => setComponentState({...componentState, ctaText: cta_text}), [cta_text]);
  useEffect(() => setComponentState({...componentState, showDate: show_date}), [show_date]);
  useEffect(() => setComponentState({...componentState, showExcerpt: show_excerpt}), [show_excerpt]);

  const cssClasses = classnames('nv-feed-aggregator');
  return (
    <>
      <div className={cssClasses}>
        <StateContext.Provider value={[componentState, setComponentState]}>
          {layout === FeedAggregatorLayout.three_cols.id && <ThreeColumnsLayout/>}
          {layout === FeedAggregatorLayout.four_cols.id && <FourColumnsLayout/>}
        </StateContext.Provider>
      </div>
    </>
  );
};

export default FeedAggregator;
