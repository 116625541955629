import {FeedAggregatorDataProvider} from './editor';
import axios from 'axios';

/**
 * Builds card object.
 *
 * @param {String} id
 * @param {String} image
 * @param {String} title
 * @param {String} date
 * @param {String} excerpt
 * @param {String} link
 * @param {Number|String} timestamp
 * @returns {{date, image, link, excerpt, title}}
 */
const buildCardItem = (image, title, date, excerpt, link, timestamp) => {
  let id = generateUniqueId();
  return {id, image, title, date, excerpt, link, timestamp: parseInt(timestamp)}
};

/**
 * Get image from post object.
 *
 * @param {Object} post
 * @returns {*|string}
 */
const getImage = (post) => {
  if (!post || !post.hasOwnProperty('wp:featuredmedia')) {
    return null;
  }

  const featuredMedia = post['wp:featuredmedia'].shift();
  let image = featuredMedia['source_url'];

  // Try to load smaller images.
  let imageSizes = featuredMedia.media_details?.sizes?.['medium_large'];
  if (imageSizes && imageSizes.hasOwnProperty('source_url')) {
    image = imageSizes['source_url'];
  }

  return image;
};

function generateUniqueId() {
  const timestamp = Date.now().toString(36);
  const randomStr = Math.random().toString(36).substr(2, 5);
  return timestamp + randomStr;
}

const normalizeWpApiResponse = (response) => {
  return response.map(item => {
    const {title, link, excerpt, _embedded, date} = item;
    let postTimestamp = parseInt((new Date(date).getTime() / 1000).toFixed(0));

    let postDate = new Date(date);
    let month = postDate.toLocaleString('default', { month: 'long' });

    const image = getImage(_embedded);
    return buildCardItem(image, title['rendered'], `${month} ${postDate.getDate()}, ${postDate.getFullYear()}`, excerpt['rendered'], link, postTimestamp);
  });
};

const get = async (url, queryParams) => {
  const apiClientInstance = axios.create({timeout: 2000});
  const {data} = await apiClientInstance.request({method: 'get', url, params: queryParams});

  return data ? data: [];
};

async function queryWpApi(source, count) {
  const {host, categoryIds, tagIds} = source;
  const url = `${host}/wp-json/wp/v2/posts`;

  let queryParams = {
    '_embed': true,
    per_page: count, // fetch `count` per feed so we don't need to make additional pagination requests
  };

  if (Array.isArray(categoryIds) && categoryIds.length > 0) {
    queryParams['categories'] = categoryIds.join(',');
  }

  if (Array.isArray(tagIds) && tagIds.length > 0) {
    queryParams['tags'] = tagIds.join(',');
  }

  return await get(url, queryParams);
}

export const loadDataFromSource = async (source, count) => {
  let data = [];
  const {dataProvider: provider} = source;

  switch (provider) {
    case FeedAggregatorDataProvider.wordpressApi:
      data = await queryWpApi(source, count);
      break;
  }

  return data;
};

export const normalizeResponseData = async (rawData, provider) => {
  let normalized = [];

  switch (provider) {
    case FeedAggregatorDataProvider.wordpressApi:
      normalized = normalizeWpApiResponse(rawData);
      break;
  }

  return normalized;
}
