import * as React from 'react';
import {useState} from 'react';
import {CardDataFields, AccordionDataFields} from '../data-mapping';

export const tsConnectionContext = React.createContext({});

// Field mappings.
export const FieldsMappingContext = React.createContext({
  card: [],
  accordion: []
});

export const FieldsMappingContextProvider = ({children}) => {
  const [cardMapping, setCardMapping] = useState(CardDataFields);
  const [accordionMapping, setAccordionMapping] = useState(AccordionDataFields);

  const store = {
    card: [cardMapping, setCardMapping],
    accordion: [accordionMapping, setAccordionMapping]
  };
  return <FieldsMappingContext.Provider value={store}>
    {children}
  </FieldsMappingContext.Provider>
};

export const ViewOptionsContext = React.createContext({
  card: []
});

export const ViewOptionsContextProvider = ({children}) => {
  const [cardViewOptions, setCardViewOptions] = useState({
    hasImagePadding: false,
    isClickableCard: false,
  });

  const store = {
    card: [cardViewOptions, setCardViewOptions],
  };
  return <ViewOptionsContext.Provider value={store}>
    {children}
  </ViewOptionsContext.Provider>
}
