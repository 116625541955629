import * as React from 'react';
import { SearchBox } from 'react-instantsearch-dom';

export const FacetedSearchBox = () => {
  return <div className="fs-widget fs-widget--text">
    <SearchBox translations={{
      placeholder: I18n.t('faceted_search.filters.search_box.placeholder'),
      submitTitle: I18n.t('faceted_search.filters.search_box.submit_title')
    }}/>
  </div>
};
